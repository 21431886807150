import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, reduce } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ServiceCall } from './service-call.service';
import { MachinesService } from './machines.service';
import { IMachineLicenses, IOrganizationLicense, IOrganizationLicensesContainer, LicenseState } from '../models/license.model';
import { ComponentType, MachineBase } from '../models/machines.model';
import { License } from '../models/machine-overview.model';

@Injectable()
export class LicenseService {
  private licenseUrlV2 = `${environment.apiBaseUrl}/v3/organizations`;

  constructor(private serviceCall: ServiceCall, private machinesService: MachinesService) {}

  getFarmLicenses(organizationId: string, language: string): Observable<IOrganizationLicensesContainer> {
    return this.serviceCall
      .get(`${this.licenseUrlV2}/${organizationId}/licenses?language=${language}`)
      .pipe(map((response) => response.data as IOrganizationLicensesContainer));
  }

  activateOrganizationLicense(organizationId: string, licenseId: string, language: string): Observable<IOrganizationLicense> {
    return this.serviceCall
      .patch(`${this.licenseUrlV2}/${organizationId}/licenses/${licenseId}?language=${language}`, { state: 'ACTIVE' })
      .pipe(map((response) => response.data));
  }

  getLicensesForMachines(machines: MachineBase[], language: string): Observable<IMachineLicenses[]> {
    return from(machines).pipe(
      mergeMap((machine) =>
        this.machinesService
          .getLicenses(machine.id, language, !(machine.type === ComponentType.DISPLAY || machine.type === ComponentType.GPS_RECEIVER))
          .pipe(
            map((licenses: License[]) => {
              return licenses && licenses.length > 0
                ? [{ licenses, ...machine, expanded: false }]
                : [{ ...machine, licenses: [], expanded: false }];
            }),
            catchError(() => {
              return of([{ ...machine, licenses: [], expanded: false }]);
            })
          )
      ),
      reduce((acc, val) => acc.concat(val), [] as IMachineLicenses[])
    );
  }
}
